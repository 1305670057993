import React from 'react';
import dynamic from 'next/dynamic';
import { UiKit } from '@datavant/uikit';

const OktaWidget = dynamic(() => import('./OktaWidget'), {
    ssr: false,
    loading: () => <UiKit.SpinnerWithText text={'Loading...'} mt={'0px'} />,
});

const OktaWidgetNoSSR = () => {
    return <OktaWidget />;
};

export default OktaWidgetNoSSR;

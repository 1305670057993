import React from 'react';
import { UiKit } from '@datavant/uikit';
import { RecordHubPageFooter } from '../SmartRequest/RecordHubPageContainer';
import Image from 'next/image';
import new_logo from '../../public/new-login-logo.png';
import OktaWidgetNoSSR from './OktaWidgetNoSSR';

const OktaWidgetLoginModal = (): JSX.Element => {
    const modalBody = (
        <UiKit.InfoBox>
            <UiKit.Chakra.HStack justify={'center'}>
                <Image src={new_logo} alt="Smart Request" priority width={'270'} height={'91'} />
            </UiKit.Chakra.HStack>
            <hr style={{ borderTop: '1px solid black', marginLeft: '-10%', marginRight: '-10%' }} />
            <UiKit.Hint.Info colored={true}>
                <UiKit.Text.BodyRegular>
                    {'Having trouble signing in?'}
                    <UiKit.Text.Clickable
                        text="   Help"
                        onClick={() => {
                            window.open('/open/login-questions', '_blank');
                        }}
                        underline={true}
                    />
                </UiKit.Text.BodyRegular>
            </UiKit.Hint.Info>
            <UiKit.Chakra.VStack w={'100%'} justify={'center'}>
                <div
                    style={{
                        color: UiKit.consistency.brandColors.cancelButton.font,
                        fontFamily: 'DM Sans',
                        fontWeight: 400,
                        fontSize: '28px',
                        lineHeight: '42px',
                        marginTop: '5px',
                    }}
                >
                    Smart Request
                </div>

                <OktaWidgetNoSSR />
            </UiKit.Chakra.VStack>
            <div style={{ marginLeft: '68px', marginTop: '0px' }}>
                <UiKit.Chakra.VStack spacing={UiKit.consistency.buffer} align={'left'} width={'270px'}>
                    <UiKit.Text.BodyRegular>
                        <span style={{ fontWeight: 700 }}>New to Smart Request? </span> Click below to sign up
                        for digital records retrieval from Ciox Partner Sites.
                    </UiKit.Text.BodyRegular>
                    <UiKit.Text.Clickable
                        text="Click here to sign up"
                        onClick={() => {
                            window.open('/open/self-registration', '_self');
                        }}
                        underline={true}
                    />
                    <RecordHubPageFooter dontShowModals={true} />
                </UiKit.Chakra.VStack>
            </div>
        </UiKit.InfoBox>
    );

    return (
        <UiKit.Chakra.Modal
            autoFocus={false}
            onClose={() => undefined}
            isOpen={true}
            scrollBehavior={'outside'}
            isCentered={true}
            closeOnOverlayClick={false}
        >
            <UiKit.Chakra.ModalOverlay bgColor={UiKit.consistency.gray70} />
            <UiKit.Chakra.ModalContent style={{ background: 'none', height: 'auto', maxHeight: '80vh' }}>
                <UiKit.Chakra.ModalBody padding={'0px'}>{modalBody}</UiKit.Chakra.ModalBody>
            </UiKit.Chakra.ModalContent>
        </UiKit.Chakra.Modal>
    );
};

export default OktaWidgetLoginModal;
